
const awsconfig = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_C2muWBFxa",
    "aws_user_pools_web_client_id": "2qqrdtgmn1mccksp3kmi80hta4"
};


export default awsconfig;
