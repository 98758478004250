import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';

import TranslatePasswordBody from './TranslatePasswordBody';
import TranslatePasswordFooter from './TranslatePasswordFooter';
import TranslatePasswordHeader from './TranslatePasswordHeader';

import { connect } from 'react-redux'
import { } from '../redux/actions'

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

function TranslatePassword(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <TranslatePasswordHeader />
        <TranslatePasswordBody />
        <Divider className={classes.divider} variant="middle" />
        <TranslatePasswordFooter />
      </Paper>
    </React.Fragment>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
  }
}

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslatePassword)

