import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { connect } from 'react-redux'
import {
} from '../redux/actions'

function copyToClipboard(someValue) {
  // guard clause - modern approach worked
  let modernSuccessful = copyToClipboardModern(someValue);
  if (modernSuccessful) {
    return true;
  }
  
  // guard clause - hack approach worked
  let hackSuccessful = copyToClipboardHack(someValue);
  if (hackSuccessful) {
    return true;
  }
  
  // otherwise, unsuccessful
  return false;
}

//
// https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API
//
function copyToClipboardModern(someValue) {
    // guard clause - clipboard not available
    if (!navigator.clipboard) {
      return false;
    }
    
    navigator.clipboard.writeText(someValue).then(function() {
      console.log("Modern worked");
      return true;
    }, function() {
      return false;
    });
}

//
// https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
// seems only necessary for Safari/iOS...removed MAC specific logic at end
//
function copyToClipboardHack(someValue) {
  let textarea;
  let result;

  try {
    textarea = document.createElement('textarea');
    textarea.setAttribute('readonly', true);
    textarea.setAttribute('contenteditable', true);
    textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
    textarea.value = someValue;

    document.body.appendChild(textarea);

    textarea.focus();
    textarea.select();

    const range = document.createRange();
    range.selectNodeContents(textarea);

    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);

    textarea.setSelectionRange(0, textarea.value.length);
    result = document.execCommand('copy');
  } catch (err) {
    console.error(err);
    result = null;
  } finally {
    document.body.removeChild(textarea);
  }

  if (!result) {
    return false;
  }
  return true;
}

function ShowSite(props) {
  const [passwordSnackbarOpen, setPasswordSnackbarOpen] = React.useState(false);
  const [siteSnackbarOpen, setSiteSnackbarOpen] = React.useState(false);
  const [useridSnackbarOpen, setUseridSnackbarOpen] = React.useState(false);

  const handleCopyTargetPasswordValue = () => {
    // guard clause - unable to copy to clipboard
    let targetPasswordValue = props.targetPasswordValue || '';
    let copySuccessful = copyToClipboard(targetPasswordValue);
    if (!copySuccessful) {
      alert("Unfortunately, unable to the clipboard...you'll need to type this: " + targetPasswordValue);
      return;
    }
    
    // otherwise, communicate success
    setPasswordSnackbarOpen(true);
  }

  const handleCopyTargetSiteLink = () => {
    // guard clause - unable to copy to clipboard
    let targetSiteLink = props.selectedTargetSiteInfo.siteLink || '';
    let copySuccessful = copyToClipboard(targetSiteLink);
    if (!copySuccessful) {
      alert("Unfortunately, unable to the clipboard...you'll need to type this: " + targetSiteLink);
      return;
    }
    
    // otherwise, communicate success
      setSiteSnackbarOpen(true);
  }

  const handleCopyTargetUseridValue = () => {
    // guard clause - unable to copy to clipboard
    let targetUseridValue = props.selectedTargetSiteInfo.siteUserid || '';
    let copySuccessful = copyToClipboard(targetUseridValue);
    if (!copySuccessful) {
      alert("Unfortunately, unable to the clipboard...you'll need to type this: " + targetUseridValue);
      return;
    }
    
    // otherwise, communicate success
      setUseridSnackbarOpen(true);
  }

  const handlePasswordSnackbarClose = (event, reason) => {
    setPasswordSnackbarOpen(false);
  };
  
  const handleSiteSnackbarClose = (event, reason) => {
    setSiteSnackbarOpen(false);
  };
  
  const handleUseridSnackbarClose = (event, reason) => {
    setUseridSnackbarOpen(false);
  };
  
  return (
    <React.Fragment>
      <Grid container spacing={3}>
      
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Copy site information
          </Typography>
        </Grid>
        
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            Copy the site link, userid, or password, and then paste it into your browser (or wherever you want). 
            To update the site salt (when resetting your site password) or the site information, use the actions from the toolbar.
          </Typography>
        </Grid>
        
        <Grid item xs={12}>
          <TextField
            autoComplete="target-site-name"
            disabled
            id="targetSiteName"
            name="targetSiteName"
            label="Target Site Name"
            fullWidth
            value={props.selectedTargetSiteInfo.siteName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="target-site-salt"
            disabled
            id="targetSiteSalt"
            name="targetSiteSalt"
            label="Target Site Salt"
            fullWidth
            value={props.targetSaltValue}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="target-site-link"
            disabled
            id="targetSiteLink"
            name="targetSiteLink"
            label="Target Site Link"
            fullWidth
            type="url"
            value={props.selectedTargetSiteInfo.siteLink}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="target-userid-value"
            disabled
            id="targetUseridValue"
            name="targetUseridValue"
            label="Target Userid Value"
            fullWidth
            value={props.selectedTargetSiteInfo.siteUserid}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="target-password-value"
            disabled
            id="targetPasswordValue"
            name="targetPasswordValue"
            label="Target Password Value"
            fullWidth
            type="password"
            value={props.targetPasswordValue}
          />
        </Grid>
        
        <Grid item xs={4}>
          <Button
            variant="contained"
            color="secondary"
            disabled={!props.selectedTargetSiteInfo.siteLink}
            onClick={(event) => handleCopyTargetSiteLink()}
          >
            Link
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            color="secondary"
            disabled={!props.selectedTargetSiteInfo.siteUserid}
            onClick={(event) => handleCopyTargetUseridValue()}
          >
            Userid
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            autoFocus
            variant="contained"
            color="secondary"
            disabled={!props.targetPasswordValue}
            onClick={(event) => handleCopyTargetPasswordValue()}
          >
            Password
          </Button>
        </Grid>
        
      </Grid>
      
      <Snackbar 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={passwordSnackbarOpen} 
        autoHideDuration={1000} 
        onClose={handlePasswordSnackbarClose}
        message="Password copied"
      />
      <Snackbar 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={siteSnackbarOpen} 
        autoHideDuration={1000} 
        onClose={handleSiteSnackbarClose}
        message="Site copied"
      />
      <Snackbar 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={useridSnackbarOpen} 
        autoHideDuration={1000} 
        onClose={handleUseridSnackbarClose}
        message="Userid copied"
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    selectedTargetSiteInfo: state.targetPasswordReducer.selectedTargetSiteInfo,
    targetPasswordValue: state.targetPasswordReducer.targetAdjustedHashValue,
    targetSaltValue: state.targetPasswordReducer.targetSaltValue,
  }
}

const mapDispatchToProps = { 
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowSite)

