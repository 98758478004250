import {
  OVERALL_PAGE_DECREMENT_STEP,
  OVERALL_PAGE_INCREMENT_STEP
} from './actionTypes'

const overallSteps = [
  'Enter source password', 
  'Choose target site', 
  'Copy site information'
];

const overallPageInitialState = {
  activeStep: 0,
  lastStep: overallSteps.length - 1,
  steps: overallSteps
}

export function overallPageReducer(state = overallPageInitialState, action) {
  switch (action.type) {
    case OVERALL_PAGE_DECREMENT_STEP:
      return { 
        ...state, 
        activeStep: state.activeStep - 1
      }
    case OVERALL_PAGE_INCREMENT_STEP:
      return { 
        ...state, 
        activeStep: state.activeStep + 1
      }
    default:
      return state
  }
}

