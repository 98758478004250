import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import Copyright from './Copyright';
import OverallMenu from './OverallMenu';
import TranslatePassword from './TranslatePassword';
import TranslateToken from './TranslateToken';

import { connect } from 'react-redux'
import {
  overallAppAmplifySignIn,
  overallAppInitialize
} from '../redux/actions'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'static',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  toolbarMenu: {
    marginRight: theme.spacing(2),
  },  
}));

function RosettaMain(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container spacing={3}>
      
        <Grid item xs={12}>
          <CssBaseline />
        </Grid>
        
        <Grid item xs={12}>
          <AppBar position="absolute" color="default" className={classes.appBar}>
             <Toolbar>
              <OverallMenu toolbarButtonStyle={classes.toolbarMenu} />
              <Typography component="h6" variant="h4" align="center">
                Rosetta Salt Application
              </Typography>
            </Toolbar>
          </AppBar>
        </Grid>
      
        <Grid item xs={12}>
          <main className={classes.layout}>
            {props.serverTokenExpired ? (
              <TranslateToken />
            ) : (
              <TranslatePassword />
            )}
          </main>
        </Grid>
        
        <Grid item xs={12}>
          <Copyright />
        </Grid>
        
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    serverTokenExpired: state.serverTokenReducer.serverTokenExpired,
  }
}

const mapDispatchToProps = {
  overallAppAmplifySignIn,
  overallAppInitialize
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RosettaMain)

