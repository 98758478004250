import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import RequestTokenClaim from './RequestTokenClaim';
import RequestTokenRequest from './RequestTokenRequest';

import { connect } from 'react-redux'
import {
} from '../redux/actions'

function RequestToken(props) {
  return (
    <React.Fragment>
      <Grid container spacing={3}>
      
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Request Rosetta Salt token
          </Typography>
        </Grid>
        
      
        {!props.claimTokenRequestId ? (
          <RequestTokenRequest />
        ) : (
          <RequestTokenClaim />
        )}
        
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    claimTokenRequestId: state.serverTokenReducer.claimTokenRequestId,
  }
}

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestToken)

