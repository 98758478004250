export const OVERALL_APP_GET_USER_ERROR = 'OVERALL_APP_GET_USER_ERROR'
export const OVERALL_APP_GET_USER_STARTED = 'OVERALL_APP_GET_USER_STARTED'
export const OVERALL_APP_GET_USER_SUCCESS = 'OVERALL_APP_GET_USER_SUCCESS'
export const OVERALL_APP_RESET_USER = 'OVERALL_APP_RESET_USER'

export const OVERALL_PAGE_DECREMENT_STEP = 'OVERALL_PAGE_DECREMENT_STEP'
export const OVERALL_PAGE_INCREMENT_STEP = 'OVERALL_PAGE_INCREMENT_STEP'

export const SERVER_TOKEN_CLAIM_TOKEN_CANCEL = 'SERVER_TOKEN_CLAIM_TOKEN_CANCEL'
export const SERVER_TOKEN_CLAIM_TOKEN_ERROR = 'SERVER_TOKEN_CLAIM_TOKEN_ERROR'
export const SERVER_TOKEN_CLAIM_TOKEN_STARTED = 'SERVER_TOKEN_CLAIM_TOKEN_STARTED'
export const SERVER_TOKEN_CLAIM_TOKEN_SUCCESS = 'SERVER_TOKEN_CLAIM_TOKEN_SUCCESS'
export const SERVER_TOKEN_ERASE_TOKEN = 'SERVER_TOKEN_ERASE_TOKEN'
export const SERVER_TOKEN_REQUEST_TOKEN_ERROR = 'SERVER_TOKEN_REQUEST_TOKEN_ERROR'
export const SERVER_TOKEN_REQUEST_TOKEN_STARTED = 'SERVER_TOKEN_REQUEST_TOKEN_STARTED'
export const SERVER_TOKEN_REQUEST_TOKEN_SUCCESS = 'SERVER_TOKEN_REQUEST_TOKEN_SUCCESS'
export const SERVER_TOKEN_SET_REQUEST_TOKEN_INFO = 'SERVER_TOKEN_SET_REQUEST_TOKEN_INFO'
export const SERVER_TOKEN_SET_TOKEN_EXPIRED = 'SERVER_TOKEN_SET_TOKEN_EXPIRED'

export const SOURCE_PASSWORD_CHANGE_PASSWORD_VALUE = 'SOURCE_PASSWORD_CHANGE_PASSWORD_VALUE'
export const SOURCE_PASSWORD_PERSIST_HASH_VALUE = 'SOURCE_PASSWORD_PERSIST_HASH_VALUE'

export const TARGET_SITE_ADD_NEW_SITE_ERROR = 'TARGET_SITE_ADD_NEW_SITE_ERROR'
export const TARGET_SITE_ADD_NEW_SITE_STARTED = 'TARGET_SITE_ADD_NEW_SITE_STARTED'
export const TARGET_SITE_ADD_NEW_SITE_SUCCESS = 'TARGET_SITE_ADD_NEW_SITE_SUCCESS'
export const TARGET_SITE_CLOSE_DELETE_SITE_DIALOG = 'TARGET_SITE_CLOSE_DELETE_SITE_DIALOG'
export const TARGET_SITE_CLOSE_NEW_SITE_DIALOG = 'TARGET_SITE_CLOSE_NEW_SITE_DIALOG'
export const TARGET_SITE_DELETE_SELECTED_SITE_ERROR = 'TARGET_SITE_DELETE_SELECTED_SITE_ERROR'
export const TARGET_SITE_DELETE_SELECTED_SITE_STARTED = 'TARGET_SITE_DELETE_SELECTED_SITE_STARTED'
export const TARGET_SITE_DELETE_SELECTED_SITE_SUCCESS = 'TARGET_SITE_DELETE_SELECTED_SITE_SUCCESS'
export const TARGET_SITE_GET_TARGETS_ERROR = 'TARGET_SITE_GET_TARGETS_ERROR'
export const TARGET_SITE_GET_TARGETS_STARTED = 'TARGET_SITE_GET_TARGETS_STARTED'
export const TARGET_SITE_GET_TARGETS_SUCCESS = 'TARGET_SITE_GET_TARGETS_SUCCESS'
export const TARGET_SITE_OPEN_DELETE_SITE_DIALOG = 'TARGET_SITE_OPEN_DELETE_SITE_DIALOG'
export const TARGET_SITE_OPEN_NEW_SITE_DIALOG = 'TARGET_SITE_OPEN_NEW_SITE_DIALOG'
export const TARGET_SITE_SELECT_TARGET = 'TARGET_SITE_SELECT_TARGET'
export const TARGET_SITE_SET_NEW_SITE_INFO = 'TARGET_SITE_SET_NEW_SITE_INFO'

export const TARGET_PASSWORD_CLOSE_UPDATE_SALT_DIALOG = 'TARGET_PASSWORD_CLOSE_UPDATE_SALT_DIALOG'
export const TARGET_PASSWORD_CLOSE_UPDATE_SITE_DIALOG = 'TARGET_PASSWORD_CLOSE_UPDATE_SITE_DIALOG'
export const TARGET_PASSWORD_LOAD_SPEC_ERROR = 'TARGET_PASSWORD_LOAD_SPEC_ERROR'
export const TARGET_PASSWORD_LOAD_SPEC_STARTED = 'TARGET_PASSWORD_LOAD_SPEC_STARTED'
export const TARGET_PASSWORD_LOAD_SPEC_SUCCESS = 'TARGET_PASSWORD_LOAD_SPEC_SUCCESS'
export const TARGET_PASSWORD_OPEN_UPDATE_SALT_DIALOG = 'TARGET_PASSWORD_OPEN_UPDATE_SALT_DIALOG'
export const TARGET_PASSWORD_OPEN_UPDATE_SITE_DIALOG = 'TARGET_PASSWORD_OPEN_UPDATE_SITE_DIALOG'
export const TARGET_PASSWORD_SET_UPDATE_SITE_INFO = 'TARGET_PASSWORD_SET_UPDATE_SITE_INFO'
export const TARGET_PASSWORD_UPDATE_SITE_ERROR = 'TARGET_PASSWORD_UPDATE_SITE_ERROR'
export const TARGET_PASSWORD_UPDATE_SITE_STARTED = 'TARGET_PASSWORD_UPDATE_SITE_STARTED'
export const TARGET_PASSWORD_UPDATE_SITE_SUCCESS = 'TARGET_PASSWORD_UPDATE_SITE_SUCCESS'
