import {
  OVERALL_APP_GET_USER_ERROR,
  OVERALL_APP_GET_USER_STARTED,
  OVERALL_APP_GET_USER_SUCCESS,
  OVERALL_APP_RESET_USER
} from './actionTypes'

import { commonGetErrorMessage } from './reducers-common'

const jwt = require('jsonwebtoken');

const overallAppInitialState = {
  appAmplifyUser: null,
  appAmplifyUserError: '',
  appAmplifyUserIdJwtToken: '',
  appAmplifyUserIdJwtEmail: '',
  appVersionValue: '1.0.16',
}

export function overallAppReducer(state = overallAppInitialState, action) {
  let errorMessage = "";
  switch (action.type) {
    case OVERALL_APP_GET_USER_ERROR:
      errorMessage = commonGetErrorMessage(action.errorResponse);
      return { 
        ...state, 
        appAmplifyUserError: errorMessage
      }
    case OVERALL_APP_GET_USER_STARTED:
      return { 
        ...state, 
        appAmplifyUser: null,
        appAmplifyUserError: '',
        appAmplifyUserIdJwtToken: '',
        appAmplifyUserIdJwtEmail: '',
      }
    case OVERALL_APP_GET_USER_SUCCESS:
      let idJwtToken = action.appAmplifyUser.signInUserSession.idToken.jwtToken;
      let idJwtTokenPayload = jwt.decode(idJwtToken);
      let idJwtEmail = idJwtTokenPayload.email;
      return { 
        ...state, 
        appAmplifyUser: action.appAmplifyUser,
        appAmplifyUserIdJwtToken: idJwtToken,
        appAmplifyUserIdJwtEmail: idJwtEmail,
      }
    case OVERALL_APP_RESET_USER:
      return { 
        ...state, 
        appAmplifyUser: null,
        appAmplifyUserError: '',
        appAmplifyUserIdJwtToken: '',
        appAmplifyUserIdJwtEmail: '',
      }
    default:
      return state
  }
}

