import {
  SOURCE_PASSWORD_CHANGE_PASSWORD_VALUE,
  SOURCE_PASSWORD_PERSIST_HASH_VALUE
} from './actionTypes'

import { cryptoCreateHash } from './reducers-common'
import { cryptoRandomBytes } from './reducers-common'

if (!localStorage.sourcePasswordPersistentSaltValue) {
  localStorage.sourcePasswordPersistentSaltValue = cryptoRandomBytes(64).toString('hex');
}
let sourcePasswordPersistentSaltValue = localStorage.sourcePasswordPersistentSaltValue;

if (!localStorage.sourcePasswordPersistentHashValue) {
  localStorage.sourcePasswordPersistentHashValue = '';
}
let sourcePasswordPersistentHashValue = localStorage.sourcePasswordPersistentHashValue;

function sourcePasswordCreatePasswordHash(passwordValue, passwordSalt) {
  let passwordHash = cryptoCreateHash('sha512');
  passwordHash.update(passwordValue + passwordSalt);
  return passwordHash.digest('hex');
}

const sourcePasswordInitialState = {
  persistentSaltValue: sourcePasswordPersistentSaltValue,
  persistentHashValue: sourcePasswordPersistentHashValue,
  transientPasswordValue: '',
  transientHashValue: sourcePasswordCreatePasswordHash('', sourcePasswordPersistentSaltValue)
}

export function sourcePasswordReducer(state = sourcePasswordInitialState, action) {
  switch (action.type) {
    case SOURCE_PASSWORD_CHANGE_PASSWORD_VALUE:
      return { 
        ...state, 
        transientPasswordValue: action.passwordValue,
        transientHashValue: sourcePasswordCreatePasswordHash(action.passwordValue, state.persistentSaltValue)
      }
    case SOURCE_PASSWORD_PERSIST_HASH_VALUE:
      localStorage.sourcePasswordPersistentHashValue = state.transientHashValue;
      return { 
        ...state, 
        persistentHashValue: state.transientHashValue
      }
    default:
      return state
  }
}

