import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { connect } from 'react-redux'
import {
  targetPasswordCloseUpdateSiteDialog,
  targetPasswordSetUpdateSiteInfo,
  targetPasswordUpdateSite
} from '../redux/actions'

function UpdateSite(props) {
  const handleUpdateSiteCancel = () => {
    props.targetPasswordCloseUpdateSiteDialog();
  }
  
  const handleUpdateSiteLinkChange = (event) => {
    let updateSiteInfo = {
      ...props.updateSiteInfo,
      'siteLink': event.target.value
    }
    props.targetPasswordSetUpdateSiteInfo(updateSiteInfo)
  }

  const handleUpdateSiteNameChange = (event) => {
    let updateSiteInfo = {
      ...props.updateSiteInfo,
      'siteName': event.target.value
    }
    props.targetPasswordSetUpdateSiteInfo(updateSiteInfo)
  }

  const handleUpdateSiteOk = () => {
    props.targetPasswordUpdateSite();
  }
  
  const handleUpdateSiteRuleForceCapitalIndexChange = (event) => {
    let updateSiteInfoRules = {
      ...props.updateSiteInfo.siteRules,
      'forceCapitalIndex': parseInt(event.target.value)
    }
    let updateSiteInfo = {
      ...props.updateSiteInfo,
      'siteRules': updateSiteInfoRules
    }
    props.targetPasswordSetUpdateSiteInfo(updateSiteInfo)
  }

  const handleUpdateSiteRuleForceNumberIndexChange = (event) => {
    let updateSiteInfoRules = {
      ...props.updateSiteInfo.siteRules,
      'forceNumberIndex': parseInt(event.target.value)
    }
    let updateSiteInfo = {
      ...props.updateSiteInfo,
      'siteRules': updateSiteInfoRules
    }
    props.targetPasswordSetUpdateSiteInfo(updateSiteInfo)
  }

  const handleUpdateSiteRuleForceSpecialIndexChange = (event) => {
    let updateSiteInfoRules = {
      ...props.updateSiteInfo.siteRules,
      'forceSpecialIndex': parseInt(event.target.value)
    }
    let updateSiteInfo = {
      ...props.updateSiteInfo,
      'siteRules': updateSiteInfoRules
    }
    props.targetPasswordSetUpdateSiteInfo(updateSiteInfo)
  }

  const handleUpdateSiteRuleForceSpecialCharacterChange = (event) => {
    let updateSiteInfoRules = {
      ...props.updateSiteInfo.siteRules,
      'forceSpecialCharacter': event.target.value
    }
    let updateSiteInfo = {
      ...props.updateSiteInfo,
      'siteRules': updateSiteInfoRules
    }
    props.targetPasswordSetUpdateSiteInfo(updateSiteInfo)
  }

  const handleUpdateSiteRuleStartIndexChange = (event) => {
    let updateSiteInfoRules = {
      ...props.updateSiteInfo.siteRules,
      'startIndex': parseInt(event.target.value)
    }
    let updateSiteInfo = {
      ...props.updateSiteInfo,
      'siteRules': updateSiteInfoRules
    }
    props.targetPasswordSetUpdateSiteInfo(updateSiteInfo)
  }

  const handleUpdateSiteRuleStopIndexChange = (event) => {
    let updateSiteInfoRules = {
      ...props.updateSiteInfo.siteRules,
      'stopIndex': parseInt(event.target.value)
    }
    let updateSiteInfo = {
      ...props.updateSiteInfo,
      'siteRules': updateSiteInfoRules
    }
    props.targetPasswordSetUpdateSiteInfo(updateSiteInfo)
  }

  const handleUpdateSiteUseridChange = (event) => {
    let updateSiteInfo = {
      ...props.updateSiteInfo,
      'siteUserid': event.target.value
    }
    props.targetPasswordSetUpdateSiteInfo(updateSiteInfo)
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
      
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Update Site Info
          </Typography>
        </Grid>
        
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
              Specify the revised site information here.
          </Typography>
        </Grid>
        
        <Grid item xs={12}>
          <TextField
            autoComplete="update-site-name"
            autoFocus
            id="updateSiteName"
            name="updateSiteName"
            label="Site Name"
            fullWidth
            onChange={(event) => handleUpdateSiteNameChange(event)}
            type="text"
            value={props.updateSiteInfo.siteName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="update-site-link"
            id="updateSiteLink"
            name="updateSiteLink"
            label="Site Link"
            fullWidth
            onChange={(event) => handleUpdateSiteLinkChange(event)}
            type="text"
            value={props.updateSiteInfo.siteLink}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="updateSiteUserid"
            name="updateSiteUserid"
            label="Site Userid"
            fullWidth
            onChange={(event) => handleUpdateSiteUseridChange(event)}
            type="text"
            value={props.updateSiteInfo.siteUserid}
          />
        </Grid>
        
        <Grid item xs={12}>
          <TextField
            id="updateSiteStartIndex"
            name="updateSiteStartIndex"
            label="Start Index"
            fullWidth
            onChange={(event) => handleUpdateSiteRuleStartIndexChange(event)}
            type="text"
            value={props.updateSiteInfo.siteRules.startIndex}
          />
          <TextField
            id="updateSiteStopIndex"
            name="updateSiteStopIndex"
            label="Stop Index"
            fullWidth
            onChange={(event) => handleUpdateSiteRuleStopIndexChange(event)}
            type="text"
            value={props.updateSiteInfo.siteRules.stopIndex}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="updateSiteForceCapitalIndex"
            name="updateSiteForceCapitalIndex"
            label="Force Capital Index"
            fullWidth
            onChange={(event) => handleUpdateSiteRuleForceCapitalIndexChange(event)}
            type="text"
            value={props.updateSiteInfo.siteRules.forceCapitalIndex}
          />
          <TextField
            id="updateSiteForceNumberIndex"
            name="updateSiteForceNumberIndex"
            label="Force Number Index"
            fullWidth
            onChange={(event) => handleUpdateSiteRuleForceNumberIndexChange(event)}
            type="text"
            value={props.updateSiteInfo.siteRules.forceNumberIndex}
          />
        </Grid>
        
        <Grid item xs={12}>
          <TextField
            id="updateSiteForceSpecialIndex"
            name="updateSiteForceSpecialIndex"
            label="Force Special Index"
            fullWidth
            onChange={(event) => handleUpdateSiteRuleForceSpecialIndexChange(event)}
            type="text"
            value={props.updateSiteInfo.siteRules.forceSpecialIndex}
          />
          <TextField
            id="updateSiteForceSpecialCharacter"
            name="updateSiteForceSpecialCharacter"
            label="Force Special Character"
            fullWidth
            onChange={(event) => handleUpdateSiteRuleForceSpecialCharacterChange(event)}
            type="text"
            value={props.updateSiteInfo.siteRules.forceSpecialCharacter}
          />
        </Grid>
        
        <Grid item xs={6}>
          <Button color='primary' onClick={(event) => handleUpdateSiteOk()}>
            OK
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={(event) => handleUpdateSiteCancel()}>
            Cancel
          </Button>
        </Grid>
        
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    updateSiteInfo: state.targetPasswordReducer.updateSiteInfo
  }
}

const mapDispatchToProps = { 
  targetPasswordCloseUpdateSiteDialog,
  targetPasswordSetUpdateSiteInfo,
  targetPasswordUpdateSite
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateSite)

