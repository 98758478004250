import {
  OVERALL_PAGE_DECREMENT_STEP,
  OVERALL_PAGE_INCREMENT_STEP
} from './actionTypes'

export function overallPageGoToNextPage() {
  return (dispatch, getState) => {
    dispatch(overallPageIncrementStep());
  };
}

export function overallPageGoToPrevPage() {
  return (dispatch, getState) => {
    dispatch(overallPageDecrementStep());
  };
}

export function overallPageDecrementStep() {
  return { type: OVERALL_PAGE_DECREMENT_STEP }
}

export function overallPageIncrementStep() {
  return { type: OVERALL_PAGE_INCREMENT_STEP }
}

