import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { connect } from 'react-redux'
import { 
  targetSiteAddNewSite,
  targetSiteCloseNewSiteDialog,
  targetSiteSetNewSiteInfo
} from '../redux/actions'

function AddSite(props) {
  const areInputsValid = () => {
    // guard clause - invalid site name
    let siteName = props.newSiteInfo.siteName;
    if (!siteName || siteName.length <= 0) {
      return false;
    }
    
    return true;
  }
  
  const handleAddSiteCancel = () => {
    props.targetSiteCloseNewSiteDialog();
  }
  
  const handleAddSiteOk = () => {
    props.targetSiteAddNewSite();
  }
  
  const handleNewSiteLinkChange = (event) => {
    let newSiteInfo = {
      ...props.newSiteInfo,
      'siteLink': event.target.value
    }
    props.targetSiteSetNewSiteInfo(newSiteInfo)
  }

  const handleNewSiteNameChange = (event) => {
    let newSiteInfo = {
      ...props.newSiteInfo,
      'siteName': event.target.value
    }
    props.targetSiteSetNewSiteInfo(newSiteInfo)
  }

  const handleNewSiteUseridChange = (event) => {
    let newSiteInfo = {
      ...props.newSiteInfo,
      'siteUserid': event.target.value
    }
    props.targetSiteSetNewSiteInfo(newSiteInfo)
  }

  return (
   <React.Fragment>
    <Grid container spacing={3}>
    
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Add New Site
        </Typography>
      </Grid>
    
      <Grid item xs={12}>
        <Typography variant="body1" gutterBottom>
          Specify the new site information here and then click the OK button.
        </Typography>
      </Grid>
        
      <Grid item xs={12}>
        <TextField
          autoFocus
          id="newSiteName"
          name="newSiteName"
          label="Site Name"
          fullWidth
          onChange={(event) => handleNewSiteNameChange(event)}
          type="text"
          value={props.newSiteInfo.siteName}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          autoFocus
          id="newSiteLink"
          name="newSiteLink"
          label="Site Link"
          fullWidth
          onChange={(event) => handleNewSiteLinkChange(event)}
          type="url"
          value={props.newSiteInfo.siteLink}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="newSiteUserid"
          name="newSiteUserid"
          label="Site Userid"
          fullWidth
          onChange={(event) => handleNewSiteUseridChange(event)}
          type="text"
          value={props.newSiteInfo.siteUserid}
        />
      </Grid>
      {props.addNewSiteError && (
        <Grid item xs={12}>
          <TextField
            disabled
            id="newSiteAddError"
            name="newSiteAddError"
            label="Add New Site Error"
            fullWidth
            type="text"
            value={props.addNewSiteError}
          />
        </Grid>
      )}
      
      <Grid item xs={6}>
        <Button
          color='primary' 
          disabled={!areInputsValid()}
          onClick={(event) => handleAddSiteOk()}
        >
          OK
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button onClick={(event) => handleAddSiteCancel()}>
          Cancel
        </Button>
      </Grid>
      
    </Grid>
   </React.Fragment>
 );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    addNewSiteError: state.targetSiteReducer.addNewSiteError,
    newSiteInfo: state.targetSiteReducer.newSiteInfo,
  }
}

const mapDispatchToProps = { 
  targetSiteAddNewSite,
  targetSiteCloseNewSiteDialog,
  targetSiteSetNewSiteInfo
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSite)

