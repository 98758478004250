import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { connect } from 'react-redux'
import {
  sourcePasswordPersistHashValue,
  targetPasswordOpenUpdateSaltDialog,
  targetPasswordOpenUpdateSiteDialog,
  targetSiteGetTargets,
  targetSiteOpenDeleteSiteDialog,
  targetSiteOpenNewSiteDialog,
} from '../redux/actions'

function TranslatePasswordMenu(props) {
  const [manageAnchorElement, setManageAnchorElement] = React.useState(null);
  
  // Common
  
  const handleManageClose = () => {
    setManageAnchorElement(null);
  }
  
  const handleManageOpen = (event) => {
    setManageAnchorElement(event.currentTarget);
  }
  
  const isSiteSelected = () => {
    if (props.selectedSpec.siteName == null) {
      return false;
    } else if (props.selectedSpec.siteId == null) {
      return false;
    } else {
      return true;
    }
  }

  // Source Password
  
  const areHashValuesEqual = () => {
    return props.persistentHashValue === props.transientHashValue;
  };

  const handleUpdatePersistentHashValue = () => {
    setManageAnchorElement(null);
    props.sourcePasswordPersistHashValue();
  };

  // Target Sites
  
  const handleAddSiteOpen = () => {
    setManageAnchorElement(null);
    props.targetSiteOpenNewSiteDialog();
  }

  const handleDeleteSiteOpen = () => {
    setManageAnchorElement(null);
    props.targetSiteOpenDeleteSiteDialog();
  }

  const handleRefreshSites = () => {
    setManageAnchorElement(null);
    props.targetSiteGetTargets();
  }

  // Target Site
  
  const handleUpdateSaltOpen = () => {
    setManageAnchorElement(null);
    props.targetPasswordOpenUpdateSaltDialog();
  }

  const handleUpdateSiteOpen = () => {
    setManageAnchorElement(null);
    props.targetPasswordOpenUpdateSiteDialog();
  }

  // Toolbar Menus (conditional based on active step)
  
  return (
    <React.Fragment>

      <IconButton edge="start" className={props.toolbarButtonStyle} color="inherit" aria-label="menu" onClick={handleManageOpen}>
        <MenuIcon />
      </IconButton>

      {props.activeStep === 0 && (
        <Menu
          id="toolbar-menu"
          anchorEl={manageAnchorElement}
          keepMounted
          open={Boolean(manageAnchorElement)}
          onClose={handleManageClose}
        >
          <MenuItem disabled={areHashValuesEqual()} onClick={handleUpdatePersistentHashValue}>Update Saved Hash Value</MenuItem>
        </Menu>
      )}
      
      {props.activeStep === 1 && (
        <Menu
          id="toolbar-menu"
          anchorEl={manageAnchorElement}
          keepMounted
          open={Boolean(manageAnchorElement)}
          onClose={handleManageClose}
        >
          <MenuItem onClick={(event) => handleRefreshSites()}>Refresh Sites</MenuItem>
          <MenuItem onClick={(event) => handleAddSiteOpen()}>Add Site</MenuItem>
          <MenuItem disabled={!isSiteSelected()} onClick={(event) => handleDeleteSiteOpen()}>Delete Site</MenuItem>
        </Menu>
      )}
      
      {props.activeStep === 2 && (
        <Menu
          id="toolbar-menu"
          anchorEl={manageAnchorElement}
          keepMounted
          open={Boolean(manageAnchorElement)}
          onClose={handleManageClose}
        >
          <MenuItem disabled={!isSiteSelected()} onClick={(event) => handleUpdateSaltOpen()}>Update Site Salt</MenuItem>
          <MenuItem disabled={!isSiteSelected()} onClick={(event) => handleUpdateSiteOpen()}>Update Site Info</MenuItem>
        </Menu>
      )}
      
        
    </React.Fragment>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    activeStep: state.overallPageReducer.activeStep,
    persistentHashValue: state.sourcePasswordReducer.persistentHashValue,
    selectedSpec: state.targetSiteReducer.selectedTargetSiteSpec,
    transientHashValue: state.sourcePasswordReducer.transientHashValue
  }
}

const mapDispatchToProps = {
  sourcePasswordPersistHashValue,
  targetPasswordOpenUpdateSaltDialog,
  targetPasswordOpenUpdateSiteDialog,
  targetSiteGetTargets,
  targetSiteOpenDeleteSiteDialog,
  targetSiteOpenNewSiteDialog,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslatePasswordMenu)

