import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { connect } from 'react-redux'
import {
  targetPasswordCloseUpdateSaltDialog,
  targetPasswordSetUpdateSiteInfo,
  targetPasswordUpdateSite
} from '../redux/actions'

function UpdateSalt(props) {
  const handleUpdateSiteCancel = () => {
    props.targetPasswordCloseUpdateSaltDialog();
  }
  
  const handleUpdateSiteOk = () => {
    let updateSiteInfo = {
      ...props.updateSiteInfo,
      'renewSiteSalt': true
    }
    props.targetPasswordSetUpdateSiteInfo(updateSiteInfo)
    props.targetPasswordUpdateSite();
  }
  
  return (
    <React.Fragment>
      <Grid container spacing={3}>
      
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Update Site Salt
          </Typography>
        </Grid>
        
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
              A typical reason to update a site's salt is that your password with that site has expired.
              By updating the site's salt, you can keep the same source password and still significantly change the site password.
          </Typography>
        </Grid>
        
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
              However, make sure that you have copied the current derived password before you do this.
              Otherwise, you may need to go through a password reset workflow with the site.
          </Typography>
        </Grid>
        
        <Grid item xs={6}>
          <Button color='primary' onClick={(event) => handleUpdateSiteOk()}>
            OK
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={(event) => handleUpdateSiteCancel()}>
            Cancel
          </Button>
        </Grid>
        
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    updateSiteInfo: state.targetPasswordReducer.updateSiteInfo
  }
}

const mapDispatchToProps = { 
  targetPasswordCloseUpdateSaltDialog,
  targetPasswordSetUpdateSiteInfo,
  targetPasswordUpdateSite
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateSalt)

