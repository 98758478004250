import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { connect } from 'react-redux'
import {
  sourcePasswordChangePasswordValue, 
  sourcePasswordPersistHashValue,
  targetSiteGetTargets
} from '../redux/actions'

const useStyles = makeStyles(theme => ({
  lockContainer: {
    display: 'flex',
  },
  lockComponent: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

function SourcePassword(props) {
  const classes = useStyles();

  // only trigger when component first rendered
  // https://stackoverflow.com/questions/55915024/call-a-redux-action-inside-a-useeffect
  const { targetSiteGetTargets } = props;
  useEffect(() => {
    targetSiteGetTargets();
  }, [targetSiteGetTargets]);  
  
  const areHashValuesEqual = () => {
    return props.persistentHashValue === props.transientHashValue;
  };

  const getHashValuesEqualSwitchColor = () => {
    if (areHashValuesEqual()) {
      return 'primary';
    } else {
      return 'secondary';
    }
  };

  const handlePasswordValueChange = (event) => {
    props.sourcePasswordChangePasswordValue(event.target.value);
  };

  return (
    <React.Fragment>

      <Typography variant="h6" gutterBottom>
        Enter source password
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {props.persistentHashValue ? (
            <Typography variant="body1" gutterBottom>
              Type your source password until its hash value matches your previously saved value.
              The lock badge will show you the length of your password and indicate when the hashes match.
            </Typography>
          ) : (
            <Typography variant="body1" gutterBottom>
              Type a source password that balances complexity and ease of entry and then save its hash from the toolbar.
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <TextField
            autoComplete="persistent-hash-value"
            disabled
            id="persistentHashValue"
            name="persistentHashValue"
            label="Saved Hash Value"
            fullWidth
            value={props.persistentHashValue}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="transient-hash-value"
            disabled
            id="transientHashValue"
            name="transientHashValue"
            label="Source Hash Value"
            fullWidth
            value={props.transientHashValue}
          />
        </Grid>
        
        <Grid item xs={12} className={classes.lockContainer}>
          <Badge
            color={getHashValuesEqualSwitchColor()} badgeContent={props.transientPasswordValue.length}
            className={classes.lockComponent}
          >
            <LockIcon />
          </Badge>
          <TextField
            autoComplete="transient-password-value"
            autoFocus
            required
            id="transientPasswordValue"
            name="transientPasswordValue"
            label="Source Password Value"
            fullWidth
            onChange={handlePasswordValueChange}
            placeholder="Enter your source password here"
            type="password"
            value={props.transientPasswordValue}
          />
        </Grid>
        
      </Grid>

    </React.Fragment>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    persistentSaltValue: state.sourcePasswordReducer.persistentSaltValue,
    persistentHashValue: state.sourcePasswordReducer.persistentHashValue,
    serverTokenValue: state.serverTokenReducer.serverTokenValue,
    transientPasswordValue: state.sourcePasswordReducer.transientPasswordValue,
    transientHashValue: state.sourcePasswordReducer.transientHashValue
  }
}

const mapDispatchToProps = {
  sourcePasswordChangePasswordValue, 
  sourcePasswordPersistHashValue,
  targetSiteGetTargets
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SourcePassword)

