import crypto from 'crypto'

export function commonGetErrorMessage(errorResponse) {
    let errorMessage = "Unknown error";
    if (errorResponse && errorResponse.data && errorResponse.data.Message) {
      errorMessage = errorResponse.data.Message
    }
    return errorMessage;
}

export function cryptoCreateHash(hashAlgorithm) {
  return crypto.createHash(hashAlgorithm);
}

export function cryptoRandomBytes(numberOfBytes) {
  return crypto.randomBytes(numberOfBytes);
}

