import axios from 'axios'
import { isTokenExpired } from './utility'

export function commonCreateAxiosClientWithAmplifyToken(state) {
  const axiosClient = axios.create();
  axiosClient.defaults.baseURL = state.serverTokenReducer.serverBaseUri;
  console.log("Amplify token: " + state.overallAppReducer.appAmplifyUserIdJwtToken);
  axiosClient.defaults.headers.common['Authorization'] = "Bearer " + state.overallAppReducer.appAmplifyUserIdJwtToken;
  axiosClient.defaults.headers.common['X-Device-Id'] = state.serverTokenReducer.deviceId;
  return axiosClient;
}

export function commonCreateAxiosClientWithTranslateToken(state) {
  const axiosClient = axios.create();
  axiosClient.defaults.baseURL = state.serverTokenReducer.serverBaseUri;
  axiosClient.defaults.headers.common['Authorization'] = "Bearer " + state.serverTokenReducer.serverTokenValue;
  axiosClient.defaults.headers.common['X-Device-Id'] = state.serverTokenReducer.deviceId;
  return axiosClient;
}

export function commonIsTranslateTokenExpired(state) {
  return isTokenExpired(state.serverTokenReducer.serverTokenExpirationSeconds);
}
