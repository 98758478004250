import {
  TARGET_SITE_ADD_NEW_SITE_ERROR,
  TARGET_SITE_ADD_NEW_SITE_STARTED,
  TARGET_SITE_ADD_NEW_SITE_SUCCESS,
  TARGET_SITE_CLOSE_DELETE_SITE_DIALOG,
  TARGET_SITE_CLOSE_NEW_SITE_DIALOG,
  TARGET_SITE_DELETE_SELECTED_SITE_ERROR,
  TARGET_SITE_DELETE_SELECTED_SITE_STARTED,
  TARGET_SITE_DELETE_SELECTED_SITE_SUCCESS,
  TARGET_SITE_GET_TARGETS_STARTED,
  TARGET_SITE_GET_TARGETS_SUCCESS,
  TARGET_SITE_GET_TARGETS_ERROR,
  TARGET_SITE_OPEN_DELETE_SITE_DIALOG,
  TARGET_SITE_OPEN_NEW_SITE_DIALOG,
  TARGET_SITE_SELECT_TARGET,
  TARGET_SITE_SET_NEW_SITE_INFO
} from './actionTypes'

import { commonCreateAxiosClientWithTranslateToken } from './actions-common'
import { commonIsTranslateTokenExpired } from './actions-common'

import { serverTokenSetTokenExpired } from './actions-serverToken'

import { targetPasswordLoadSpec } from './actions-targetPassword'

export function targetSiteAddNewSite() {
  return (dispatch, getState) => {
    // guard clause - translate token already expired
    if (commonIsTranslateTokenExpired(getState())) {
      dispatch(serverTokenSetTokenExpired());
      return;
    }
    
    dispatch(targetSiteAddNewSiteStarted());
    const axiosClient = commonCreateAxiosClientWithTranslateToken(getState());
    const newSiteInfo = getState().targetSiteReducer.newSiteInfo;
    axiosClient
      .post("/api/target-sites", newSiteInfo)
      .then(res => { 
        let newSiteSpec = res.data;
        dispatch(targetSiteAddNewSiteSuccess(newSiteSpec));
        dispatch(targetSiteSelectTargetAndPreloadSiteSpec(newSiteSpec));
      })
      .catch(err => { dispatch(targetSiteAddNewSiteError(err.response)); });
  };
}

export function targetSiteAddNewSiteError(errorResponse) {
  return { type: TARGET_SITE_ADD_NEW_SITE_ERROR, errorResponse }
}

export function targetSiteAddNewSiteStarted() {
  return { type: TARGET_SITE_ADD_NEW_SITE_STARTED }
}

export function targetSiteAddNewSiteSuccess(newSiteSpec) {
  return { type: TARGET_SITE_ADD_NEW_SITE_SUCCESS, newSiteSpec }
}

export function targetSiteCloseDeleteSiteDialog() {
  return { type: TARGET_SITE_CLOSE_DELETE_SITE_DIALOG }
}

export function targetSiteCloseNewSiteDialog() {
  return { type: TARGET_SITE_CLOSE_NEW_SITE_DIALOG }
}

export function targetSiteDeleteSelectedSite() {
  return (dispatch, getState) => {
    // guard clause - translate token already expired
    if (commonIsTranslateTokenExpired(getState())) {
      dispatch(serverTokenSetTokenExpired());
      return;
    }
    
    dispatch(targetSiteDeleteSelectedSiteStarted());
    const axiosClient = commonCreateAxiosClientWithTranslateToken(getState());
    const selectedTargetSiteSpec = getState().targetSiteReducer.selectedTargetSiteSpec;
    axiosClient
      .delete("/api/target-sites/" + selectedTargetSiteSpec.siteId)
      .then(res => { dispatch(targetSiteDeleteSelectedSiteSuccess()); })
      .catch(err => { dispatch(targetSiteDeleteSelectedSiteError(err.response)); });
  };
}

export function targetSiteDeleteSelectedSiteError(errorResponse) {
  return { type: TARGET_SITE_DELETE_SELECTED_SITE_ERROR, errorResponse }
}

export function targetSiteDeleteSelectedSiteStarted() {
  return { type: TARGET_SITE_DELETE_SELECTED_SITE_STARTED }
}

export function targetSiteDeleteSelectedSiteSuccess() {
  return { type: TARGET_SITE_DELETE_SELECTED_SITE_SUCCESS }
}

export function targetSiteGetTargets() {
  return (dispatch, getState) => {
    // guard clause - translate token already expired
    if (commonIsTranslateTokenExpired(getState())) {
      dispatch(serverTokenSetTokenExpired());
      return;
    }
    
    dispatch(targetSiteGetTargetsStarted());
    const axiosClient = commonCreateAxiosClientWithTranslateToken(getState());
    axiosClient
      .get("/api/target-sites")
      .then(res => { dispatch(targetSiteGetTargetsSuccess(res.data)); })
      .catch(err => { dispatch(targetSiteGetTargetsError(err.response)); });
  };
}

export function targetSiteGetTargetsError(errorResponse) {
  return { type: TARGET_SITE_GET_TARGETS_ERROR, errorResponse }
}

export function targetSiteGetTargetsStarted() {
  return { type: TARGET_SITE_GET_TARGETS_STARTED }
}

export function targetSiteGetTargetsSuccess(targets) {
  return { type: TARGET_SITE_GET_TARGETS_SUCCESS, targets }
}

export function targetSiteOpenDeleteSiteDialog() {
  return { type: TARGET_SITE_OPEN_DELETE_SITE_DIALOG }
}

export function targetSiteOpenNewSiteDialog() {
  return { type: TARGET_SITE_OPEN_NEW_SITE_DIALOG }
}

export function targetSiteSelectTarget(selectedTargetSiteSpec) {
  return { type: TARGET_SITE_SELECT_TARGET, selectedTargetSiteSpec }
}

export function targetSiteSelectTargetAndPreloadSiteSpec(selectedTargetSiteSpec) {
  return (dispatch) => {
    dispatch(targetSiteSelectTarget(selectedTargetSiteSpec));
    dispatch(targetPasswordLoadSpec(selectedTargetSiteSpec.siteId));
  };
}

export function targetSiteSetNewSiteInfo(newSiteInfo) {
  return { type: TARGET_SITE_SET_NEW_SITE_INFO, newSiteInfo }
}

