import React from 'react';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { connect } from 'react-redux'
import {
} from '../redux/actions'

function Copyright(props) {
  return (
    <React.Fragment>
      <Grid container spacing={3}>
      
        <Grid item xs={12}>
          {props.serverTokenExpiration && (
              <Typography variant="body2" color="textSecondary" align="center" noWrap={true}>
                {props.serverTokenExpiration}
              </Typography>
          )}
          
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://rosettasalt.org/">
              Rosetta Salt
            </Link>{' '}
            {new Date().getFullYear()}
            {' (' + props.appVersionValue + ')'}
          </Typography>
        </Grid>
    
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    appVersionValue: state.overallAppReducer.appVersionValue,
    serverTokenExpiration: state.serverTokenReducer.serverTokenExpiration,
  }
}

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Copyright)


