import {
  TARGET_SITE_ADD_NEW_SITE_ERROR,
  TARGET_SITE_ADD_NEW_SITE_STARTED,
  TARGET_SITE_ADD_NEW_SITE_SUCCESS,
  TARGET_SITE_CLOSE_DELETE_SITE_DIALOG,
  TARGET_SITE_CLOSE_NEW_SITE_DIALOG,
  TARGET_SITE_DELETE_SELECTED_SITE_ERROR,
  TARGET_SITE_DELETE_SELECTED_SITE_STARTED,
  TARGET_SITE_DELETE_SELECTED_SITE_SUCCESS,
  TARGET_SITE_GET_TARGETS_ERROR,
  TARGET_SITE_GET_TARGETS_STARTED,
  TARGET_SITE_GET_TARGETS_SUCCESS,
  TARGET_SITE_OPEN_DELETE_SITE_DIALOG,
  TARGET_SITE_OPEN_NEW_SITE_DIALOG,
  TARGET_SITE_SELECT_TARGET,
  TARGET_SITE_SET_NEW_SITE_INFO
} from './actionTypes'

import { commonGetErrorMessage } from './reducers-common'

const targetSiteInitialState = {
  addNewSiteError: '',
  deleteSiteDialog: false,
  deleteSelectedSiteError: '',
  newSiteDialog: false,
  newSiteInfo: {
    'siteLink': '',
    'siteName': '',
    'siteUserid': ''
  },
  potentialTargetsError: null,
  potentialTargetsSuccess: false,
  potentialTargetSiteSpecs: [],
  selectedTargetSiteSpec: {}
}

export function targetSiteReducer(state = targetSiteInitialState, action) {
  let errorMessage = "";
  switch (action.type) {
    case TARGET_SITE_ADD_NEW_SITE_ERROR:
      errorMessage = commonGetErrorMessage(action.errorResponse);
      console.log("Add new site error: " + errorMessage);
      return { 
        ...state, 
        addNewSiteError: errorMessage
      }
    case TARGET_SITE_ADD_NEW_SITE_STARTED:
      return state
    case TARGET_SITE_ADD_NEW_SITE_SUCCESS:
      let newSiteSpecs = Array.from(state.potentialTargetSiteSpecs);
      let newPotentialTargetSiteSpecs = newSiteSpecs.concat(action.newSiteSpec);
      return { 
        ...state, 
        newSiteDialog: false,
        potentialTargetSiteSpecs: newPotentialTargetSiteSpecs
      }
    case TARGET_SITE_CLOSE_DELETE_SITE_DIALOG:
      return { 
        ...state, 
        deleteSiteDialog: false
      }
    case TARGET_SITE_CLOSE_NEW_SITE_DIALOG:
      return { 
        ...state, 
        newSiteDialog: false
      }
    case TARGET_SITE_DELETE_SELECTED_SITE_ERROR:
      errorMessage = commonGetErrorMessage(action.errorResponse);
      console.log("Delete selected site error: " + errorMessage)
      return { 
        ...state, 
        deleteSelectedSiteError: errorMessage
      }
    case TARGET_SITE_DELETE_SELECTED_SITE_STARTED:
      return state
    case TARGET_SITE_DELETE_SELECTED_SITE_SUCCESS:
      let reducedSiteSpecs = [];
      state.potentialTargetSiteSpecs.forEach((ptss) => {
        if (ptss.siteId !== state.selectedTargetSiteSpec.siteId) {
          reducedSiteSpecs.push(ptss);
        }
      })
      return { 
        ...state, 
        deleteSiteDialog: false,
        potentialTargetSiteSpecs: reducedSiteSpecs,
        selectedTargetSiteSpec: {}
      }
    case TARGET_SITE_GET_TARGETS_ERROR:
      errorMessage = commonGetErrorMessage(action.errorResponse);
      console.log("Get targets error: " + errorMessage)
      return { 
        ...state, 
        potentialTargetsError: errorMessage
      }
    case TARGET_SITE_GET_TARGETS_STARTED:
      return { 
        ...state, 
        potentialTargetsError: null,
        potentialTargetsSuccess: false
      }
    case TARGET_SITE_GET_TARGETS_SUCCESS:
      let oldSelectedTargetSiteId = state.selectedTargetSiteSpec.siteId;
      let potentialTargetSiteSpecs = action.targets;
      let newSelectedTargetSiteSpec = potentialTargetSiteSpecs.find(ptss => ptss.siteId === oldSelectedTargetSiteId) || {};
      return { 
        ...state, 
        potentialTargetsSuccess: true,
        potentialTargetSiteSpecs: action.targets,
        selectedTargetSiteSpec: newSelectedTargetSiteSpec
      }
    case TARGET_SITE_OPEN_DELETE_SITE_DIALOG:
      return { 
        ...state, 
        deleteSelectedSiteError: '',
        deleteSiteDialog: true
      }
    case TARGET_SITE_OPEN_NEW_SITE_DIALOG:
      return { 
        ...state,
        addNewSiteError: '',
        newSiteDialog: true
      }
    case TARGET_SITE_SELECT_TARGET:
      return { 
        ...state, 
        selectedTargetSiteSpec: action.selectedTargetSiteSpec
      }
    case TARGET_SITE_SET_NEW_SITE_INFO:
      return { 
        ...state, 
        newSiteInfo: action.newSiteInfo
      }
    default:
      return state
  }
}

