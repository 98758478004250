import {
  SOURCE_PASSWORD_CHANGE_PASSWORD_VALUE,
} from './actionTypes'

import {
  TARGET_PASSWORD_CLOSE_UPDATE_SALT_DIALOG,
  TARGET_PASSWORD_CLOSE_UPDATE_SITE_DIALOG,
  TARGET_PASSWORD_LOAD_SPEC_STARTED,
  TARGET_PASSWORD_LOAD_SPEC_SUCCESS,
  TARGET_PASSWORD_LOAD_SPEC_ERROR,
  TARGET_PASSWORD_OPEN_UPDATE_SALT_DIALOG,
  TARGET_PASSWORD_OPEN_UPDATE_SITE_DIALOG,
  TARGET_PASSWORD_SET_UPDATE_SITE_INFO,
  TARGET_PASSWORD_UPDATE_SITE_ERROR,
  TARGET_PASSWORD_UPDATE_SITE_STARTED,
  TARGET_PASSWORD_UPDATE_SITE_SUCCESS
} from './actionTypes'

import { commonGetErrorMessage } from './reducers-common'
import { cryptoCreateHash } from './reducers-common'

function targetPasswordAdjustPasswordHash(passwordHash, selectedTargetSiteInfo) {
  // guard clause - invalid input
  if (passwordHash == null || selectedTargetSiteInfo == null || selectedTargetSiteInfo.siteRules == null) {
    return "";
  }

  let startIndex = selectedTargetSiteInfo.siteRules.startIndex; 
  let stopIndex = selectedTargetSiteInfo.siteRules.stopIndex; 
  let transformedPasswordHash = passwordHash.substring(startIndex, stopIndex);
  
  let forceCapitalIndex = selectedTargetSiteInfo.siteRules.forceCapitalIndex;
  if (forceCapitalIndex != null && forceCapitalIndex >= 0 && forceCapitalIndex < transformedPasswordHash.length) {
    let existingChar = transformedPasswordHash.charAt(forceCapitalIndex);
    let existingCharValue = parseInt(existingChar, 16);
    let forcedCharValue = String.fromCharCode(65 + existingCharValue);
    let prefix = transformedPasswordHash.substring(0, forceCapitalIndex);
    let suffix = transformedPasswordHash.substring(forceCapitalIndex + 1, transformedPasswordHash.length);
    transformedPasswordHash = prefix + forcedCharValue + suffix;
  }
  
  let forceNumberIndex = selectedTargetSiteInfo.siteRules.forceNumberIndex;
  if (forceNumberIndex != null && forceNumberIndex >= 0 && forceNumberIndex < transformedPasswordHash.length) {
    let existingChar = transformedPasswordHash.charAt(forceNumberIndex);
    let existingCharValue = parseInt(existingChar, 16);
    let forcedNumberValue = (existingCharValue % 10).toString();
    let prefix = transformedPasswordHash.substring(0, forceNumberIndex);
    let suffix = transformedPasswordHash.substring(forceNumberIndex + 1, transformedPasswordHash.length);
    transformedPasswordHash = prefix + forcedNumberValue + suffix;
  }
  
  let forceSpecialIndex = selectedTargetSiteInfo.siteRules.forceSpecialIndex;
  let forceSpecialCharacter = selectedTargetSiteInfo.siteRules.forceSpecialCharacter;
  if (forceSpecialIndex != null && forceSpecialIndex >= 0 && forceSpecialIndex < transformedPasswordHash.length &&
      forceSpecialCharacter != null && forceSpecialCharacter.length === 1) {
    let forcedCharacterValue = forceSpecialCharacter;
    let prefix = transformedPasswordHash.substring(0, forceSpecialIndex);
    let suffix = transformedPasswordHash.substring(forceSpecialIndex + 1, transformedPasswordHash.length);
    transformedPasswordHash = prefix + forcedCharacterValue + suffix;
  }
  
  return transformedPasswordHash;
}

function targetPasswordCreatePasswordHash(passwordValue, selectedTargetSiteInfo) {
  // guard clause - invalid input
  if (selectedTargetSiteInfo == null || selectedTargetSiteInfo.siteSalt == null) {
    return "";
  }

  let passwordHash = cryptoCreateHash('sha512');
  passwordHash.update(passwordValue + selectedTargetSiteInfo.siteSalt);
  return passwordHash.digest('hex');
}

const targetPasswordInitialState = {
  selectedTargetSiteError: '',
  selectedTargetSiteInfo: {
    siteRules: {
      startIndex: 0,
      stopIndex: 10,
      forceCapitalIndex: 0,
      forceNumberIndex: 1,
      forceSpecialIndex: 2,
      forceSpecialCharacter: '%'
    },
    siteUserid: ''
  },
  targetAdjustedHashValue: '',
  targetHashValue: '',
  targetSaltValue: '',
  transientPasswordValue: '',
  updateSaltDialog: false,
  updateSiteDialog: false,
  updateSiteError: '',
  updateSiteInfo: {
    siteRules: {}
  }
}

export function targetPasswordReducer(state = targetPasswordInitialState, action) {
  let errorMessage = "";
  let newHashValue = "";
  let newAdjustedHashValue = "";
  switch (action.type) {
    case SOURCE_PASSWORD_CHANGE_PASSWORD_VALUE:
      let newPasswordValue = action.passwordValue;
      let oldTargetSiteInfo = state.selectedTargetSiteInfo;
      newHashValue = targetPasswordCreatePasswordHash(newPasswordValue, oldTargetSiteInfo);
      newAdjustedHashValue = targetPasswordAdjustPasswordHash(newHashValue, oldTargetSiteInfo);
      return { 
        ...state, 
        targetAdjustedHashValue: newAdjustedHashValue,
        targetHashValue: newHashValue,
        transientPasswordValue: newPasswordValue
      }
    case TARGET_PASSWORD_CLOSE_UPDATE_SALT_DIALOG:
      return { 
        ...state, 
        updateSaltDialog: false
      }
    case TARGET_PASSWORD_CLOSE_UPDATE_SITE_DIALOG:
      return { 
        ...state, 
        updateSiteDialog: false
      }
    case TARGET_PASSWORD_LOAD_SPEC_ERROR:
      errorMessage = commonGetErrorMessage(action.errorResponse);
      return { 
        ...state, 
        selectedTargetSiteError: errorMessage
      }
    case TARGET_PASSWORD_LOAD_SPEC_STARTED:
      return state
    case TARGET_PASSWORD_LOAD_SPEC_SUCCESS:
      let oldPasswordValue = state.transientPasswordValue;
      let newTargetSiteInfo = action.selectedTargetSiteInfo;
      newHashValue = targetPasswordCreatePasswordHash(oldPasswordValue, newTargetSiteInfo);
      newAdjustedHashValue = targetPasswordAdjustPasswordHash(newHashValue, newTargetSiteInfo);
      return { 
        ...state, 
        selectedTargetSiteInfo: newTargetSiteInfo,
        targetAdjustedHashValue: newAdjustedHashValue,
        targetHashValue: newHashValue,
        targetSaltValue: newTargetSiteInfo.siteSalt,
        updateSiteInfo: newTargetSiteInfo
      }
    case TARGET_PASSWORD_OPEN_UPDATE_SALT_DIALOG:
      return { 
        ...state, 
        updateSaltDialog: true
      }
    case TARGET_PASSWORD_OPEN_UPDATE_SITE_DIALOG:
      return { 
        ...state, 
        updateSiteDialog: true
      }
    case TARGET_PASSWORD_SET_UPDATE_SITE_INFO:
      return { 
        ...state, 
        updateSiteInfo: action.updateSiteInfo
      }
    case TARGET_PASSWORD_UPDATE_SITE_ERROR:
      errorMessage = commonGetErrorMessage(action.errorResponse);
      console.log("Update site error: " + errorMessage)
      return { 
        ...state, 
        updateSiteError: errorMessage
      }
    case TARGET_PASSWORD_UPDATE_SITE_STARTED:
      return state
    case TARGET_PASSWORD_UPDATE_SITE_SUCCESS:
      let currentPasswordValue = state.transientPasswordValue;
      let updatedTargetSiteInfo = action.selectedTargetSiteInfo;
      newHashValue = targetPasswordCreatePasswordHash(currentPasswordValue, updatedTargetSiteInfo);
      newAdjustedHashValue = targetPasswordAdjustPasswordHash(newHashValue, updatedTargetSiteInfo);
      return { 
        ...state, 
        selectedTargetSiteInfo: updatedTargetSiteInfo,
        targetAdjustedHashValue: newAdjustedHashValue,
        targetHashValue: newHashValue,
        targetSaltValue: updatedTargetSiteInfo.siteSalt,
        updateSaltDialog: false,
        updateSiteDialog: false,
        updateSiteInfo: updatedTargetSiteInfo
      }
    default:
      return state
  }
}
