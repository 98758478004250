import {
  TARGET_PASSWORD_CLOSE_UPDATE_SALT_DIALOG,
  TARGET_PASSWORD_CLOSE_UPDATE_SITE_DIALOG,
  TARGET_PASSWORD_LOAD_SPEC_STARTED,
  TARGET_PASSWORD_LOAD_SPEC_SUCCESS,
  TARGET_PASSWORD_LOAD_SPEC_ERROR,
  TARGET_PASSWORD_OPEN_UPDATE_SALT_DIALOG,
  TARGET_PASSWORD_OPEN_UPDATE_SITE_DIALOG,
  TARGET_PASSWORD_SET_UPDATE_SITE_INFO,
  TARGET_PASSWORD_UPDATE_SITE_ERROR,
  TARGET_PASSWORD_UPDATE_SITE_STARTED,
  TARGET_PASSWORD_UPDATE_SITE_SUCCESS
} from './actionTypes'

import { commonCreateAxiosClientWithTranslateToken } from './actions-common'
import { commonIsTranslateTokenExpired } from './actions-common'

import { serverTokenSetTokenExpired } from './actions-serverToken'

import { targetSiteGetTargets } from './actions-targetSite'

export function targetPasswordCloseUpdateSaltDialog() {
  return { type: TARGET_PASSWORD_CLOSE_UPDATE_SALT_DIALOG }
}

export function targetPasswordCloseUpdateSiteDialog() {
  return { type: TARGET_PASSWORD_CLOSE_UPDATE_SITE_DIALOG }
}

export function targetPasswordLoadSpec(siteId) {
  return (dispatch, getState) => {
    // guard clause - translate token already expired
    if (commonIsTranslateTokenExpired(getState())) {
      dispatch(serverTokenSetTokenExpired());
      return;
    }
    
    dispatch(targetPasswordLoadSpecStarted());
    const axiosClient = commonCreateAxiosClientWithTranslateToken(getState());
    axiosClient
      .get("/api/target-sites/" + siteId)
      .then(res => { dispatch(targetPasswordLoadSpecSuccess(res.data)); })
      .catch(err => { dispatch(targetPasswordLoadSpecError(err.response)); });
  };
}

export function targetPasswordLoadSpecError(errorResponse) {
  return { type: TARGET_PASSWORD_LOAD_SPEC_ERROR, errorResponse }
}

export function targetPasswordLoadSpecStarted() {
  return { type: TARGET_PASSWORD_LOAD_SPEC_STARTED }
}

export function targetPasswordLoadSpecSuccess(selectedTargetSiteInfo) {
  return { type: TARGET_PASSWORD_LOAD_SPEC_SUCCESS, selectedTargetSiteInfo }
}

export function targetPasswordOpenUpdateSaltDialog() {
  return { type: TARGET_PASSWORD_OPEN_UPDATE_SALT_DIALOG }
}

export function targetPasswordOpenUpdateSiteDialog() {
  return { type: TARGET_PASSWORD_OPEN_UPDATE_SITE_DIALOG }
}

export function targetPasswordSetUpdateSiteInfo(updateSiteInfo) {
  return { type: TARGET_PASSWORD_SET_UPDATE_SITE_INFO, updateSiteInfo }
}

export function targetPasswordUpdateSite() {
  return (dispatch, getState) => {
    // guard clause - translate token already expired
    if (commonIsTranslateTokenExpired(getState())) {
      dispatch(serverTokenSetTokenExpired());
      return;
    }
    
    dispatch(targetPasswordUpdateSiteStarted());
    const axiosClient = commonCreateAxiosClientWithTranslateToken(getState());
    const selectedTargetSiteSpec = getState().targetSiteReducer.selectedTargetSiteSpec;
    const updateSiteInfo = getState().targetPasswordReducer.updateSiteInfo;
    axiosClient
      .patch("/api/target-sites/" + selectedTargetSiteSpec.siteId, updateSiteInfo)
      .then(res => {
          dispatch(targetPasswordUpdateSiteSuccess(res.data));
          dispatch(targetSiteGetTargets()); // in case name of selected site has changed
       })
      .catch(err => { dispatch(targetPasswordUpdateSiteError(err.response)); });
  };
}

export function targetPasswordUpdateSiteError(errorResponse) {
  return { type: TARGET_PASSWORD_UPDATE_SITE_ERROR, errorResponse }
}

export function targetPasswordUpdateSiteStarted() {
  return { type: TARGET_PASSWORD_UPDATE_SITE_STARTED }
}

export function targetPasswordUpdateSiteSuccess(selectedTargetSiteInfo) {
  return { type: TARGET_PASSWORD_UPDATE_SITE_SUCCESS, selectedTargetSiteInfo }
}

