import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import TranslatePasswordMenu from './TranslatePasswordMenu';

import { connect } from 'react-redux'
import {
  overallPageGoToPrevPage,
  overallPageGoToNextPage
} from '../redux/actions'

const useStyles = makeStyles(theme => ({
  buttonGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  toolbarGrid: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  toolbarMenu: {
    marginTop: theme.spacing(2),
  },
}));

function TranslatePasswordFooter(props) {
  const classes = useStyles();

  const isBackButtonVisible = () => {
    return props.activeStep > 0;
  }
  
  const isDialogOpen = () => {
    if (props.deleteSiteDialog) {
      return true;
    } else if (props.newSiteDialog) {
      return true;
    } else if (props.updateSiteDialog) {
      return true;
    }

    return false;
  };

  const isNextButtonDisabled = () => {
    if (isDialogOpen()) {
      return true;
    }
    
    let activeStep = props.activeStep;
    if (activeStep === 0) {
      return props.persistentHashValue !== props.transientHashValue;
    } else if (activeStep === 1) {
      return props.selectedTargetSiteSpec.siteId == null;
    } else {
      return true;
    }
  };

  const isNextButtonVisible = () => {
    return props.activeStep < props.lastStep;
  }
  
  const handleBack = () => {
    props.overallPageGoToPrevPage();
  };

  const handleNext = () => {
    props.overallPageGoToNextPage();
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
      
        <Grid item xs={6} className={classes.toolbarGrid}>
          <TranslatePasswordMenu toolbarButtonStyle={classes.toolbarMenu} />
        </Grid>
        
        <Grid item xs={6} className={classes.buttonGrid}>
          {isBackButtonVisible() && (
            <Button onClick={handleBack} className={classes.button}>
              Back
            </Button>
          )}
          {isNextButtonVisible() && (
            <Button
              disabled={isNextButtonDisabled()}
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.button}
            >
              Next
            </Button>
          )}
        </Grid>
      
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    activeStep: state.overallPageReducer.activeStep,
    deleteSiteDialog: state.targetSiteReducer.deleteSiteDialog,
    lastStep: state.overallPageReducer.lastStep,
    newSiteDialog: state.targetSiteReducer.newSiteDialog,
    persistentHashValue: state.sourcePasswordReducer.persistentHashValue,
    selectedTargetSiteSpec: state.targetSiteReducer.selectedTargetSiteSpec,
    transientHashValue: state.sourcePasswordReducer.transientHashValue,
    updateSiteDialog: state.targetPasswordReducer.updateSiteDialog,
  }
}

const mapDispatchToProps = {
  overallPageGoToPrevPage,
  overallPageGoToNextPage
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslatePasswordFooter)

