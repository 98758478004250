import React from 'react';

import AddSite from './AddSite';
import DeleteSite from './DeleteSite';
import ShowSites from './ShowSites';

import { connect } from 'react-redux'
import { 
} from '../redux/actions'

function TargetSites(props) {
  return (
   <React.Fragment>
    {!props.newSiteDialog && !props.deleteSiteDialog && (
      <ShowSites />
    )}

    {props.newSiteDialog && (
      <AddSite />
    )}
    
    {props.deleteSiteDialog && (
      <DeleteSite />
    )}
   </React.Fragment>
 );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    deleteSiteDialog: state.targetSiteReducer.deleteSiteDialog,
    newSiteDialog: state.targetSiteReducer.newSiteDialog,
  }
}

const mapDispatchToProps = { 
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TargetSites)

