import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import { connect } from 'react-redux'
import { 
  targetSiteSelectTargetAndPreloadSiteSpec,
} from '../redux/actions'

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 350,
  },
}));

function TargetSites(props) {
  const classes = useStyles();
  
  const handleSelectSite = (siteSpec, event) => {
    props.targetSiteSelectTargetAndPreloadSiteSpec(siteSpec);
  }

  return (
   <React.Fragment>
    <Grid container spacing={3}>
    
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Choose target site
        </Typography>
      </Grid>
    
      <Grid item xs={12}>
        {props.siteSpecs.length > 0 ? (
          <Typography variant="body1" gutterBottom>
            Choose a target site and then click the next button to copy its information. 
            Alternatively, refresh the target sites, add new sites, or delete the selected site from the toolbar.
          </Typography>
        ) : (
          <Typography variant="body1" gutterBottom>
            Add a new site by clicking that action from the toolbar.
          </Typography>
        )}
      </Grid>
        
      <Grid item xs={12}>
        <List className={classes.list}>
        {
          props.siteSpecs.map((siteSpec) =>
            <ListItem 
                button 
                key={siteSpec.siteId} 
                selected={siteSpec.siteId === props.selectedTargetSiteSpec.siteId}
                onClick={(event) => handleSelectSite(siteSpec, event)}
            >
              <ListItemText primary={siteSpec.siteName} />
            </ListItem>
          )
        }
        </List>
      </Grid>

    </Grid>
   </React.Fragment>
 );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    selectedTargetSiteSpec: state.targetSiteReducer.selectedTargetSiteSpec,
    siteSpecs: state.targetSiteReducer.potentialTargetSiteSpecs,
  }
}

const mapDispatchToProps = { 
  targetSiteSelectTargetAndPreloadSiteSpec,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TargetSites)

