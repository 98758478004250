import {
  SERVER_TOKEN_CLAIM_TOKEN_CANCEL,
  SERVER_TOKEN_CLAIM_TOKEN_ERROR,
  SERVER_TOKEN_CLAIM_TOKEN_STARTED,
  SERVER_TOKEN_CLAIM_TOKEN_SUCCESS,
  SERVER_TOKEN_ERASE_TOKEN,
  SERVER_TOKEN_REQUEST_TOKEN_ERROR,
  SERVER_TOKEN_REQUEST_TOKEN_STARTED,
  SERVER_TOKEN_REQUEST_TOKEN_SUCCESS,
  SERVER_TOKEN_SET_REQUEST_TOKEN_INFO,
  SERVER_TOKEN_SET_TOKEN_EXPIRED
} from './actionTypes'

import { Auth } from 'aws-amplify';

import { commonCreateAxiosClientWithAmplifyToken } from './actions-common'

import { overallAppGetUserError } from './actions-overallApp'
import { overallAppGetUserStarted } from './actions-overallApp'
import { overallAppGetUserSuccess } from './actions-overallApp'

export function serverTokenClaimToken() {
  return (dispatch, getState) => {
    dispatch(serverTokenClaimTokenStarted());
    const axiosClient = commonCreateAxiosClientWithAmplifyToken(getState());
    const reducer = getState().serverTokenReducer;
    const tokenClaimInfo = {
      'deviceId': reducer.requestTokenInfo.deviceId,
      'claimValue': reducer.requestTokenInfo.claimValue,
    }
    axiosClient
      .patch("/api/token-requests/" + reducer.claimTokenRequestId, tokenClaimInfo)
      .then(res => { dispatch(serverTokenClaimTokenSuccess(res.data)) })
      .catch(err => { dispatch(serverTokenClaimTokenError(err.response)) });
  }
}

export function serverTokenRefreshUserThenClaimToken() {
  return (dispatch, getState) => {
    dispatch(overallAppGetUserStarted());
    Auth.currentAuthenticatedUser( { bypassCache: false } )
    .then(user => {
      dispatch(overallAppGetUserSuccess(user));
      dispatch(serverTokenClaimToken());
    })
    .catch(err => { dispatch(overallAppGetUserError(err.response)); });
  }
}

export function serverTokenEraseToken() {
  return { type: SERVER_TOKEN_ERASE_TOKEN }
}

export function serverTokenRequestToken() {
  return (dispatch, getState) => {
    dispatch(serverTokenRequestTokenStarted());
    const axiosClient = commonCreateAxiosClientWithAmplifyToken(getState());
    const appReducer = getState().overallAppReducer;
    const tokenReducer = getState().serverTokenReducer;
    const tokenRequestInfo = {
      'deviceId': tokenReducer.requestTokenInfo.deviceId,
      'durationSeconds': tokenReducer.requestTokenInfo.durationHours * 60 * 60,
      'userId': appReducer.appAmplifyUserIdJwtEmail,
    }
    axiosClient
      .post("/api/token-requests", tokenRequestInfo)
      .then(res => { dispatch(serverTokenRequestTokenSuccess(res.data)) })
      .catch(err => { dispatch(serverTokenRequestTokenError(err.response)) });
  }
}

export function serverTokenRefreshUserThenRequestToken() {
  return (dispatch, getState) => {
    dispatch(overallAppGetUserStarted());
    Auth.currentAuthenticatedUser( { bypassCache: false } )
    .then(user => {
      dispatch(overallAppGetUserSuccess(user));
      dispatch(serverTokenRequestToken());
    })
    .catch(err => { dispatch(overallAppGetUserError(err.response)); });
  }
}

export function serverTokenClaimTokenCancel() {
  return { type: SERVER_TOKEN_CLAIM_TOKEN_CANCEL }
}

export function serverTokenClaimTokenError(errorResponse) {
  return { type: SERVER_TOKEN_CLAIM_TOKEN_ERROR, errorResponse }
}

export function serverTokenClaimTokenStarted() {
  return { type: SERVER_TOKEN_CLAIM_TOKEN_STARTED }
}

export function serverTokenClaimTokenSuccess(bearerToken) {
  return { type: SERVER_TOKEN_CLAIM_TOKEN_SUCCESS, bearerToken }
}

export function serverTokenRequestTokenError(errorResponse) {
  return { type: SERVER_TOKEN_REQUEST_TOKEN_ERROR, errorResponse }
}

export function serverTokenRequestTokenStarted() {
  return { type: SERVER_TOKEN_REQUEST_TOKEN_STARTED }
}

export function serverTokenRequestTokenSuccess(tokenRequestId) {
  return { type: SERVER_TOKEN_REQUEST_TOKEN_SUCCESS, tokenRequestId }
}

export function serverTokenSetRequestTokenInfo(requestTokenInfo) {
  return { type: SERVER_TOKEN_SET_REQUEST_TOKEN_INFO, requestTokenInfo }
}

export function serverTokenSetTokenExpired() {
  return { type: SERVER_TOKEN_SET_TOKEN_EXPIRED }
}
