import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { connect } from 'react-redux'
import {
  serverTokenEraseToken
} from '../redux/actions'

function OverallMenu(props) {
  const [manageAnchorElement, setManageAnchorElement] = React.useState(null);
  
  // Menu Handlers
  
  const handleManageClose = () => {
    setManageAnchorElement(null);
  }
  
  const handleManageOpen = (event) => {
    setManageAnchorElement(event.currentTarget);
  }
  
  // Event Handlers
  
  const handleRosettaSaltLogout = () => {
    setManageAnchorElement(null);
    props.serverTokenEraseToken();
  };

  // Component Spec
  
  return (
    <React.Fragment>

      <IconButton edge="start" className={props.toolbarButtonStyle} color="inherit" aria-label="menu" onClick={handleManageOpen}>
        <MenuIcon />
      </IconButton>

      <Menu
        id="toolbar-menu"
        anchorEl={manageAnchorElement}
        keepMounted
        open={Boolean(manageAnchorElement)}
        onClose={handleManageClose}
      >
        <MenuItem disabled={!props.serverTokenValue} onClick={handleRosettaSaltLogout}>Rosetta Salt Logout</MenuItem>
      </Menu>
        
    </React.Fragment>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    serverTokenValue: state.serverTokenReducer.serverTokenValue
  }
}

const mapDispatchToProps = {
  serverTokenEraseToken
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverallMenu)

