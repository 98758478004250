import React from 'react';

import SourcePassword from './SourcePassword';
import TargetSites from './TargetSites';
import TargetPassword from './TargetPassword';

import { connect } from 'react-redux'
import { } from '../redux/actions'

function TranslatePasswordBody(props) {
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <SourcePassword />;
      case 1:
        return <TargetSites />;
      case 2:
        return <TargetPassword />;
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <React.Fragment>
      {getStepContent(props.activeStep)}
    </React.Fragment>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    activeStep: state.overallPageReducer.activeStep,
  }
}

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslatePasswordBody)

