import {
  SOURCE_PASSWORD_CHANGE_PASSWORD_VALUE,
  SOURCE_PASSWORD_PERSIST_HASH_VALUE
} from './actionTypes'

export function sourcePasswordChangePasswordValue(passwordValue) {
  return { type: SOURCE_PASSWORD_CHANGE_PASSWORD_VALUE, passwordValue }
}

export function sourcePasswordPersistHashValue() {
  return { type: SOURCE_PASSWORD_PERSIST_HASH_VALUE }
}

