import React, { useEffect } from 'react';

import { connect } from 'react-redux'
import {
  overallAppAmplifySignIn,
  overallAppAmplifySignOut,
  overallAppInitialize
} from './redux/actions'

import Amplify from "aws-amplify";
import { Hub, Logger } from 'aws-amplify';
import {
  AmplifyAuthenticator,
  AmplifyForgotPassword,
  AmplifySignIn,
  AmplifySignOut,
  AmplifySignUp,
} from "@aws-amplify/ui-react";

import './App.css';
import awsconfig from "./config/aws-config";
import RosettaMain from './component/RosettaMain.js';

Amplify.configure(awsconfig);

function App(props) {
  // https://aws-amplify.github.io/docs/js/hub#listening-authentication-events
  const logger = new Logger('Amplify-Logger');
  const listener = (data) => {
      switch (data.payload.event) {
          case 'signIn':
              props.overallAppAmplifySignIn();
              break;
          case 'signOut':
              props.overallAppAmplifySignOut();
              break;
          case 'configured':
              logger.info('Amplify Auth module is now configured');
              break;
          default:
              logger.info('Unhandled event: ' + data.payload.event);
              break;
      }
  }

  useEffect(() => {
    props.overallAppInitialize();
    Hub.listen('auth', listener);
  });  
  
  return (
    <AmplifyAuthenticator usernameAlias="email" >
      <AmplifyForgotPassword usernameAlias="email" headerText="Reset your Rosetta Salt password" slot="forgot-password" />
      <AmplifySignIn usernameAlias="email" headerText="Sign in to your Rosetta Salt account" slot="sign-in" />
      <AmplifySignUp usernameAlias="email" headerText="Create a new Rosetta Salt account" slot="sign-up" />
      
      <div className="App">
        <RosettaMain />
        <AmplifySignOut />        
      </div>
    </AmplifyAuthenticator>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
  }
}

const mapDispatchToProps = {
  overallAppAmplifySignIn,
  overallAppAmplifySignOut,
  overallAppInitialize
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
