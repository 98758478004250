import {
  SERVER_TOKEN_CLAIM_TOKEN_CANCEL,
  SERVER_TOKEN_CLAIM_TOKEN_ERROR,
  SERVER_TOKEN_CLAIM_TOKEN_STARTED,
  SERVER_TOKEN_CLAIM_TOKEN_SUCCESS,
  SERVER_TOKEN_ERASE_TOKEN,
  SERVER_TOKEN_REQUEST_TOKEN_ERROR,
  SERVER_TOKEN_REQUEST_TOKEN_STARTED,
  SERVER_TOKEN_REQUEST_TOKEN_SUCCESS,
  SERVER_TOKEN_SET_REQUEST_TOKEN_INFO,
  SERVER_TOKEN_SET_TOKEN_EXPIRED
} from './actionTypes'

import { commonGetErrorMessage } from './reducers-common'
import { cryptoRandomBytes } from './reducers-common'
import { isTokenExpired } from './utility'

if (!localStorage.serverTokenPersistentDeviceId) {
  localStorage.serverTokenPersistentDeviceId = cryptoRandomBytes(64).toString('hex');
}
let serverTokenPersistentDeviceId = localStorage.serverTokenPersistentDeviceId;

function serverTokenInstallToken(tokenValue) {
  // guard clause - invalid token value
  if (tokenValue == null) {
    return 'Invalid token value (should be in JWT format (header.payload.signature)';
  }
  
  // guard clause - invalid token length
  let tokenString = tokenValue.toString();
  if (tokenString.length > 1024) {
    return 'Invalid token value (should be in JWT format (header.payload.signature)';
  }
  
  // guard clause - invalid token structure
  let tokenParts = tokenString.split('.');
  if (tokenParts.length !== 3) {
    return 'Invalid token value (should be in JWT format (header.payload.signature)';
  }

  try {
    let payloadBase64 = tokenParts[1];
    let payloadString = atob(payloadBase64);
    let payloadJson = JSON.parse(payloadString);

    let tokenId = payloadJson['identifier'];
    let tokenExpirationSeconds = parseFloat(payloadJson['expiration']);
    let tokenExpirationDate = new Date();
    tokenExpirationDate.setTime(tokenExpirationSeconds * 1000);
    let tokenExpirationString = tokenExpirationDate.toString();
    
    localStorage.serverTokenValue = tokenValue;
    localStorage.serverTokenIdentifier = tokenId;
    localStorage.serverTokenExpiration = tokenExpirationString;
    localStorage.serverTokenExpirationSeconds = tokenExpirationSeconds;
    
    return '';
  } catch (error) {
    return 'Unable to extract payload from JWT (should be in JWT format (header.payload.signature)';
  }
}

const serverTokenInitialState = {
  claimTokenError: '',
  claimTokenRequestId: '',
  
  deviceId: serverTokenPersistentDeviceId || '',
  
  installTokenError: '',
  
  requestTokenError: '',
  requestTokenInfo: {
    deviceId: serverTokenPersistentDeviceId || '',
    durationHours: 10,
    claimValue: '',
  },
  
  serverBaseUri: "https://api.rosettasalt.org",
  
  serverTokenIdentifier: localStorage.serverTokenIdentifier || '',
  serverTokenExpiration: localStorage.serverTokenExpiration || '',
  serverTokenExpirationSeconds: localStorage.serverTokenExpirationSeconds || 0,
  serverTokenExpired: isTokenExpired(localStorage.serverTokenExpirationSeconds || 0),
  serverTokenValue: localStorage.serverTokenValue || '',
}

export function serverTokenReducer(state = serverTokenInitialState, action) {
  let errorMessage = "";
  switch (action.type) {
    case SERVER_TOKEN_CLAIM_TOKEN_CANCEL:
      return { 
        ...state, 
        claimTokenRequestId: '',
      }
    case SERVER_TOKEN_CLAIM_TOKEN_ERROR:
      errorMessage = commonGetErrorMessage(action.errorResponse);
      console.log("Claim token error: " + errorMessage)
      return { 
        ...state, 
        claimTokenError: errorMessage
      }
    case SERVER_TOKEN_CLAIM_TOKEN_STARTED:
      return { 
        ...state, 
        claimTokenError: '',
        installTokenError: '',
      }
    case SERVER_TOKEN_CLAIM_TOKEN_SUCCESS:
      let claimInstallError = serverTokenInstallToken(action.bearerToken);
      return { 
        ...state, 
        installTokenError: claimInstallError,
        serverTokenIdentifier: localStorage.serverTokenIdentifier || '',
        serverTokenExpiration: localStorage.serverTokenExpiration || '',
        serverTokenExpirationSeconds: localStorage.serverTokenExpirationSeconds || 0,
        serverTokenExpired: isTokenExpired(localStorage.serverTokenExpirationSeconds || 0),
        serverTokenValue: localStorage.serverTokenValue || '',
      }
    case SERVER_TOKEN_ERASE_TOKEN:
      localStorage.serverTokenIdentifier = '';
      localStorage.serverTokenExpiration = '';
      localStorage.serverTokenExpirationSeconds = 0;
      localStorage.serverTokenValue = '';
      return { 
        ...state,
        claimTokenRequestId: '',
        eraseTokenDialog: false,
        serverTokenIdentifier: '',
        serverTokenExpiration: '',
        serverTokenExpirationSeconds: 0,
        serverTokenExpired: true,
        serverTokenValue: ''
      }
    case SERVER_TOKEN_REQUEST_TOKEN_ERROR:
      errorMessage = commonGetErrorMessage(action.errorResponse);
      console.log("Request token error: " + errorMessage);
      return { 
        ...state, 
        requestTokenError: errorMessage
      }
    case SERVER_TOKEN_REQUEST_TOKEN_STARTED:
      let newRequestTokenInfo = {
        deviceId: serverTokenPersistentDeviceId || '',
        durationHours: 10,
        claimValue: '',
      };
      return { 
        ...state, 
        claimTokenError: '',
        requestTokenError: '',
        requestTokenInfo: newRequestTokenInfo
      }
    case SERVER_TOKEN_REQUEST_TOKEN_SUCCESS:
      return { 
        ...state, 
        claimTokenRequestId: action.tokenRequestId,
      }
    case SERVER_TOKEN_SET_REQUEST_TOKEN_INFO:
      return { 
        ...state, 
        requestTokenInfo: action.requestTokenInfo
      }
    case SERVER_TOKEN_SET_TOKEN_EXPIRED:
      return { 
        ...state, 
        serverTokenExpired: true
      }
    default:
      return state
  }
}

