import { combineReducers } from 'redux'

import { overallAppReducer } from './reducers-overallApp'
import { overallPageReducer } from './reducers-overallPage'
import { serverTokenReducer } from './reducers-serverToken'
import { sourcePasswordReducer } from './reducers-sourcePassword'
import { targetSiteReducer } from './reducers-targetSite'
import { targetPasswordReducer } from './reducers-targetPassword'

export * from './reducers-overallApp'
export * from './reducers-overallPage'
export * from './reducers-serverToken'
export * from './reducers-sourcePassword'
export * from './reducers-targetSite'
export * from './reducers-targetPassword'

const rootReducer = combineReducers({
  overallAppReducer,
  overallPageReducer,
  serverTokenReducer,
  sourcePasswordReducer,
  targetSiteReducer,
  targetPasswordReducer
})
export default rootReducer
