import {
  OVERALL_APP_GET_USER_ERROR,
  OVERALL_APP_GET_USER_STARTED,
  OVERALL_APP_GET_USER_SUCCESS,
  OVERALL_APP_RESET_USER
} from './actionTypes'

import { Auth } from 'aws-amplify';

export function overallAppInitialize() {
  return (dispatch, getState) => {
    dispatch(overallAppGetUserStarted());
    Auth.currentAuthenticatedUser( { bypassCache: false } )
    .then(user => { dispatch(overallAppGetUserSuccess(user)) })
    .catch(err => { dispatch(overallAppGetUserError(err.response)) });
  }
}

export function overallAppAmplifySignIn() {
  return (dispatch, getState) => {
    dispatch(overallAppGetUserStarted());
    Auth.currentAuthenticatedUser( { bypassCache: false } )
    .then(user => { dispatch(overallAppGetUserSuccess(user)) })
    .catch(err => { dispatch(overallAppGetUserError(err.response)) });
  }
}

export function overallAppAmplifySignOut() {
  return { type: OVERALL_APP_RESET_USER }
}

export function overallAppGetUserError(errorResponse) {
  return { type: OVERALL_APP_GET_USER_ERROR, errorResponse }
}

export function overallAppGetUserStarted() {
  return { type: OVERALL_APP_GET_USER_STARTED }
}

export function overallAppGetUserSuccess(appAmplifyUser) {
  return { type: OVERALL_APP_GET_USER_SUCCESS, appAmplifyUser }
}

