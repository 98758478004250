import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { connect } from 'react-redux'
import {
  serverTokenRefreshUserThenRequestToken
} from '../redux/actions'

function RequestTokenRequest(props) {
  const handleSubmitRequestToken = () => {
    props.serverTokenRefreshUserThenRequestToken();
  }
  
  return (
    <React.Fragment>
      <Grid container spacing={3}>
      
        <Grid item xs={12}>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            Congratulations, you have been authenticated by AWS Cognito (phase one).
            Now, request a Rosetta Salt token to authorize your use of this application (phase two).
            If you are licensed for it, you will be sent an email with the information needed to claim it.
          </Typography>
        </Grid>
        
        <Grid item xs={12}>
          <TextField
            autoFocus
            disabled
            id="requestTokenDialogUserId"
            name="requestTokenDialogUserId"
            label="Token Email"
            fullWidth
            type="email"
            value={props.requestTokenUserid}
          />
        </Grid>
        {props.serverTokenExpiration && (
          <Grid item xs={12}>
            <TextField
              autoComplete="server-token-expiration"
              disabled
              id="serverTokenExpiration"
              name="serverTokenExpiration"
              label="Token Expiration"
              fullWidth
              type="text"
              value={props.serverTokenExpiration}
            />
          </Grid>
        )}
        
        {props.appAmplifyUserError && (
          <Grid item xs={12}>
            <TextField
              autoComplete="amplify-user-error"
              disabled
              id="amplifyUserError"
              name="amplifyUserError"
              label="Amplify User Error"
              fullWidth
              type="text"
              value={props.appAmplifyUserError}
            />
          </Grid>
        )}
        {props.requestTokenError && (
          <Grid item xs={12}>
            <TextField
              autoComplete="server-token-request-error"
              disabled
              id="serverTokenRequestError"
              name="serverTokenRequestError"
              label="Request Token Error"
              fullWidth
              type="text"
              value={props.requestTokenError}
            />
          </Grid>
        )}
        
        <Grid item xs={6}>
          <Button
            color='primary' 
            onClick={(event) => handleSubmitRequestToken()}
          >
            OK
          </Button>
        </Grid>
        
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    appAmplifyUserError: state.overallAppReducer.appAmplifyUserError,
    requestTokenError: state.serverTokenReducer.requestTokenError,
    requestTokenUserid: state.overallAppReducer.appAmplifyUserIdJwtEmail,
    serverTokenExpiration: state.serverTokenReducer.serverTokenExpiration,
  }
}

const mapDispatchToProps = {
  serverTokenRefreshUserThenRequestToken
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestTokenRequest)

