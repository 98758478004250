import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';

import { connect } from 'react-redux'
import { } from '../redux/actions'

const useStyles = makeStyles(theme => ({
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
}));

function TranslatePasswordHeader(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4" align="center">
        Translate Password
      </Typography>
      <Stepper activeStep={props.activeStep} className={classes.stepper} orientation="vertical">
        {props.steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </React.Fragment>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    activeStep: state.overallPageReducer.activeStep,
    steps: state.overallPageReducer.steps,
  }
}

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslatePasswordHeader)

