import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { connect } from 'react-redux'
import { 
  targetSiteCloseDeleteSiteDialog,
  targetSiteDeleteSelectedSite,
} from '../redux/actions'

function DeleteSite(props) {
  const handleDeleteSiteCancel = () => {
    props.targetSiteCloseDeleteSiteDialog();
  }
  
  const handleDeleteSiteOk = () => {
    props.targetSiteDeleteSelectedSite();
  }
  
  return (
   <React.Fragment>
    <Grid container spacing={3}>
    
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Delete Existing Site
        </Typography>
      </Grid>
    
      <Grid item xs={12}>
        <Typography variant="body1" gutterBottom>
          Are you sure you want to delete this site?
          Click the OK button if you are.
        </Typography>
      </Grid>
        
      <Grid item xs={12}>
        <TextField
          autoFocus
          disabled
          id="selectedSiteName"
          name="selectedSiteName"
          label="Site Name"
          fullWidth
          type="text"
          value={props.selectedSpec.siteName}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          autoFocus
          disabled
          id="selectedSiteId"
          name="selectedSiteId"
          label="Site Identifier"
          fullWidth
          type="text"
          value={props.selectedSpec.siteId}
        />
      </Grid>
      {props.deleteSelectedSiteError && (
        <Grid item xs={12}>
          <TextField
            disabled
            id="selectedSiteDeleteError"
            name="selectedSiteDeleteError"
            label="Delete Existing Site Error"
            fullWidth
            type="text"
            value={props.deleteSelectedSiteError}
          />
        </Grid>
      )}
      
      <Grid item xs={6}>
        <Button color='primary' onClick={(event) => handleDeleteSiteOk()}>
          OK
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button onClick={(event) => handleDeleteSiteCancel()}>
          Cancel
        </Button>
      </Grid>

    </Grid>
   </React.Fragment>
 );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    deleteSelectedSiteError: state.targetSiteReducer.deleteSelectedSiteError,
    selectedSpec: state.targetSiteReducer.selectedTargetSiteSpec
  }
}

const mapDispatchToProps = { 
  targetSiteCloseDeleteSiteDialog,
  targetSiteDeleteSelectedSite,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteSite)

