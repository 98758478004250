import React from 'react';

import ShowSite from './ShowSite';
import UpdateSalt from './UpdateSalt';
import UpdateSite from './UpdateSite';

import { connect } from 'react-redux'
import {
} from '../redux/actions'

function TargetPassword(props) {
  return (
    <React.Fragment>
      {!props.updateSaltDialog && !props.updateSiteDialog && (
        <ShowSite />
      )}
      {props.updateSaltDialog && (
        <UpdateSalt />
      )}
      {props.updateSiteDialog && (
        <UpdateSite />
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    updateSaltDialog: state.targetPasswordReducer.updateSaltDialog,
    updateSiteDialog: state.targetPasswordReducer.updateSiteDialog,
  }
}

const mapDispatchToProps = { 
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TargetPassword)

