import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { connect } from 'react-redux'
import {
  serverTokenClaimTokenCancel,
  serverTokenRefreshUserThenClaimToken,
  serverTokenSetRequestTokenInfo
} from '../redux/actions'

function RequestTokenClaim(props) {
  const areInputsValid = () => {
    // guard clause - invalid claim value
    let claimValue = props.requestTokenInfo.claimValue;
    if (!claimValue) {
      return false;
    }
    
    return true;
  }
  
  const handleCancelClaimToken = () => {
    props.serverTokenClaimTokenCancel();
  }
  
  const handleSubmitClaimToken = () => {
    props.serverTokenRefreshUserThenClaimToken();
  }
  
  const handleRequestTokenClaimValueChange = (event) => {
    let newRequestTokenInfo = {
      ...props.requestTokenInfo,
      'claimValue': event.target.value.trim()
    }
    props.serverTokenSetRequestTokenInfo(newRequestTokenInfo)
  }
  
  return (
    <React.Fragment>
      <Grid container spacing={3}>
      
        <Grid item xs={12}>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            Provide the claim value you received in an email to claim the token.
            Note that you only have a few minutes to complete this claim process.
          </Typography>
        </Grid>
        
        <Grid item xs={12}>
          <TextField
            disabled
            id="claimTokenRequestIdentifier"
            name="claimTokenRequestIdentifier"
            label="Request Identifier"
            fullWidth
            type="text"
            value={props.claimTokenRequestId}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled
            id="claimTokenDeviceIdentifier"
            name="claimTokenDeviceIdentifier"
            label="Device Identifier"
            fullWidth
            type="text"
            value={props.deviceId}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoFocus
            id="claimTokenClaimValue"
            name="claimTokenClaimValue"
            label="Claim Value (from Email)"
            fullWidth
            onChange={(event) => handleRequestTokenClaimValueChange(event)}
            type="text"
            value={props.requestTokenInfo.claimValue}
          />
        </Grid>
        
        {props.appAmplifyUserError && (
          <Grid item xs={12}>
            <TextField
              autoComplete="amplify-user-error"
              disabled
              id="amplifyUserError"
              name="amplifyUserError"
              label="Amplify User Error"
              fullWidth
              type="text"
              value={props.appAmplifyUserError}
            />
          </Grid>
        )}
        {props.claimTokenError && (
          <Grid item xs={12}>
            <TextField
              autoComplete="server-token-claim-error"
              disabled
              id="serverTokenClaimError"
              name="serverTokenClaimError"
              label="Claim Token Error"
              fullWidth
              type="text"
              value={props.claimTokenError}
            />
          </Grid>
        )}
        {props.installTokenError && (
          <Grid item xs={12}>
            <TextField
              autoComplete="server-token-install-error"
              disabled
              id="serverTokenInstallError"
              name="serverTokenInstallError"
              label="Install Token Error"
              fullWidth
              type="text"
              value={props.installTokenError}
            />
          </Grid>
        )}
        
        <Grid item xs={6}>
          <Button 
            color='primary' 
            disabled={!areInputsValid()}
            onClick={(event) => handleSubmitClaimToken()}
          >
            OK
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={(event) => handleCancelClaimToken()}>
            Cancel
          </Button>
        </Grid>
        
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    appAmplifyUserError: state.overallAppReducer.appAmplifyUserError,
    claimTokenError: state.serverTokenReducer.claimTokenError,
    claimTokenRequestId: state.serverTokenReducer.claimTokenRequestId,
    deviceId: state.serverTokenReducer.deviceId,
    installTokenError: state.serverTokenReducer.installTokenError,
    requestTokenInfo: state.serverTokenReducer.requestTokenInfo
  }
}

const mapDispatchToProps = {
  serverTokenClaimTokenCancel,
  serverTokenRefreshUserThenClaimToken,
  serverTokenSetRequestTokenInfo
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestTokenClaim)

